import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from "../app.constants";
import { IEnvironment } from "../types";
import { CapacitorCookies } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class TfAccessTokenService {

  public constructor(@Inject(ENV_TOKEN) private readonly env: IEnvironment) {
  }

  public async destroy(): Promise<void> {
    const expiredDate = new Date((new Date()).setDate((new Date()).getDate() - 1)).toUTCString();
    await CapacitorCookies.setCookie({
      key: 'accessToken',
      value: `${this.value}`
    });
    document.cookie = `accessToken=${this.value}; path=/; expires=${expiredDate}; domain=${this.env.baseDomain}`;
    document.cookie = `accessToken=${this.value}; path=/; expires=${expiredDate}; domain=${window.location.hostname}`;
  }

  public get exist(): boolean {
    return this.value.length > 0;
  }

  public get value(): string {
    const matches = document.cookie.match(/(?:^|; )accessToken=([^;]*)/);
    return (matches && matches[1]) || '';
  }
}
