import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { TfAccessTokenService } from '../services/access-token.service';
import { TfAccessTokenHttpService } from '../services/acess-token-http.service';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { firstValueFrom } from "rxjs";
import {CODE_CHALLENGE_NAME} from "../app.constants";
import { CapacitorCookies } from '@capacitor/core';

export const canActivateApp: CanActivateFn = async (
  route: ActivatedRouteSnapshot
): Promise<boolean> => {
  const accessTokenService = inject(TfAccessTokenService);
  const accessTokenHttpService = inject(TfAccessTokenHttpService);
  const toastController = inject(ToastController);
  const router = inject(Router);
  const c = await CapacitorCookies.getCookies();
  console.log('CapacitorCookies', c);

  if (!accessTokenService.exist) {

    return true;
  } else {
    const message = 'Login process started';
    await presentToast(toastController, message);

    try {
      const response: { status: string; redirect_uri: string } =
        await firstValueFrom(accessTokenHttpService.getAuthCode$(
          route.queryParams[CODE_CHALLENGE_NAME]
        ));

      window.location.href = response.redirect_uri;
    } catch (e: any) {

      // @todo check BE response types
      const errorMessage = `There was an error during redirect: ${e.message}, please check browser settings`;
      await presentToast(toastController, errorMessage);

      accessTokenService.destroy();
      await router.navigateByUrl('/');
    }

    return false;
  }
};

async function presentToast(
  toastController: ToastController,
  message: string
) {
  const toast = await toastController.create({ message });
  await toast.present();
}
